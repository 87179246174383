<template>
  <div>
    <v-container>
      <p>
        An isotope of Dysprosium has a mass number of
        <number-value :value="massNumber"></number-value> and a +<number-value
          :value="charge"
        ></number-value>
        charge. Calculate the number of protons, electrons, and neutrons for this ion.
      </p>
    </v-container>
    <v-simple-table>
      <tbody>
        <tr v-for="(question, index) in questions" :key="question">
          <td>
            {{ question.text }}
          </td>
          <td>
            <v-text-field v-model="inputs[`${questions[index].value}`]" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'CalculateProtonsNeutronsElectronsDysprosium',
  components: {
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        protons: '',
        electrons: '',
        neutrons: '',
      },
      questions: {
        protonQuestion: {
          text: 'a) How many protons does this isotope contain?',
          value: 'protons',
        },
        neutronQuestion: {
          text: 'b) How many electrons does this isotope contain?',
          value: 'electrons',
        },
        chargeQuestion: {
          text: 'c) How many neutrons does this isotope contain?',
          value: 'neutrons',
        },
      },
    };
  },
  computed: {
    massNumber() {
      return this.taskState.getValueBySymbol('massNumber').content;
    },
    charge() {
      return this.taskState.getValueBySymbol('charge').content;
    },
  },
};
</script>
